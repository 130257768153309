<template>
  <div>
    <div>
      <v-card color="black" class="logo">
        <img :src="brewery.logoURL" class="logo" :alt="brewery.name" />
        <div class="check-container">
          <img src="@/assets/images/check_mark.png" v-if="brewery.checked === true" width="50px" alt="checked"
            class="check" />
        </div>
      </v-card>
      <v-card color="#ffffcc">
        <h2>{{ brewery.name }}</h2>
        <p style="color:black;font-size:0.8rem;">{{ brewery.description }}</p>
        <a style="text-decoration:none;" :href="brewery.siteURL" target="_blank"><v-btn x-small class="py-2 mb-2"
            color="orange lighten-2">View Site</v-btn></a>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Brew',
  data () {
    return {
      brewery: {},
    };
  },
  watch: {
    $route (to, from) {
      console.log(to);
      console.log(from);
      // react to route changes...
      this.loadData();
    },
  },
  computed: {
    ...mapGetters('brew', ['breweries'])
  },
  created () {
    console.log(this.$store.state)
    const brewery = this.$store.state.brew.breweries.find((brewery) => { return brewery.id == this.$route.params.id })
    console.log(brewery)
    this.brewery = brewery;
  }
};
</script>

<style scoped>
.logo {
  text-align: center;
}

.v-card {
  padding: .5rem;
  position: relative;
}

.v-card img.logo {
  margin: 0 auto;
}

.check-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.check {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
</style>