<template>
  <div>
    <h2>Directions</h2>
    <v-img
      :lazy-src="require('@/assets/images/map.png')"
      width="100%"
      :src="settings.map_url"
    ></v-img>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// import { results } from '@/data.js'
export default {
  name: "Home",
  computed: {
    ...mapGetters("setting", ["settings"]),
  }
}
</script>